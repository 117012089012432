import React from "react";
import Diamondeducation from "../components/Diamondeducation"
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import {connect} from "react-redux";
import SEO from "../components/SEO";

const LooseDiamonds = (props) => {
    return (
        <Layout>
            <SEO {...(props?.data?.seo?.frontmatter || {})}/>
            <Diamondeducation {...props} fields={props?.data}/>
        </Layout>
    );
};
const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(LooseDiamonds);

export const query = graphql`
fragment LooseFragment on MarkdownRemark {
      frontmatter {
      title
      description
      img{
        publicURL
      }
    }
  }
  query LoosePageQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "loose-seo"}}) {
        frontmatter {
            title
            description
        }
  }
  enLoose: markdownRemark(frontmatter: {templateKey: {eq: "en-loose"}}) {
   ...LooseFragment
  }
  deLoose: markdownRemark(frontmatter: {templateKey: {eq: "de-loose"}}) {
   ...LooseFragment
  }
  frLoose: markdownRemark(frontmatter: {templateKey: {eq: "fr-loose"}}) {
   ...LooseFragment
  }
  esLoose: markdownRemark(frontmatter: {templateKey: {eq: "es-loose"}}) {
   ...LooseFragment
  }
  heLoose: markdownRemark(frontmatter: {templateKey: {eq: "he-loose"}}) {
   ...LooseFragment
  }
  itLoose: markdownRemark(frontmatter: {templateKey: {eq: "it-loose"}}) {
   ...LooseFragment
  }
  jaLoose: markdownRemark(frontmatter: {templateKey: {eq: "ja-loose"}}) {
   ...LooseFragment
  }
  zhLoose: markdownRemark(frontmatter: {templateKey: {eq: "zh-loose"}}) {
   ...LooseFragment
  }
}
`;